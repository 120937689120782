import React from "react";
import Header from "./Header";
import Content from "./Content";
import WaveTop from "./WaveTop";
import Informations from "./Informations";
import ThreeSections from "./ThreeSections";
import Pricing from "./Pricing";
import Wave from "./Wave";
import CallToAction from "./CallToAction";
import Footer from "./Footer";

const Home = () => {
  return [
    <Header key="header" />,
    <Content key="content" />,
    <WaveTop key="wavetop" />,
    <Informations key="informations" />,
    <ThreeSections key="threesections" />,
    <Pricing key="pricing" />,
    <Wave key="wave" />,
    <CallToAction key="calltoaction" />,
    <Footer key="footer" />,
  ];
};

export default Home;
