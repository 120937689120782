import React from "react";
import Header from "./Header";
import Informations from "./Informations";
import Footer from "./Footer";

const LeMetier = () => {
  return [
    <Header key="header" />,
    <Informations key="informations" />,
    <Footer key="footer" />,
  ];
};

export default LeMetier;
